import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading,Commonh6,Commonpara} from '../Common/common.style';
import {QuoteLeft} from '@styled-icons/fa-solid/QuoteLeft';

export const TestimonialWrapper = styled.section`
    padding:100px 0px;
    background:#000;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const HeadingSpan = styled.span`
    color:#c5a47e;
`;

export const TestimonialLayout = styled.div`

`;

export const SliderOuterWrapper = styled.div`
    margin:0px -15px;
    position:relative;
    .slick-list{
        line-height:0;
    }
`;

export const SliderWrapper = styled.div`
    outline:0;
`;

export const TestimonialCard = styled.div`
    background:#151515;
    margin:15px;
    padding:30px;

    @media ${device.laptop} {
        flex-direction:column;
    }
`;

export const ImageHolder = styled.div`
    margin-bottom:20px;

    .authorImg{
        height:80px;
        width:80px;
        margin:0px auto 20px;
        border-radius:100%;
    }
`;

export const QuoteHolder = styled.div`
    text-align:center;
`;

export const StyledIconOpen = styled(QuoteLeft)`
    height:40px;
    width:40px;
    color:#de9e53;
    margin-bottom:20px;
`;

export const AuthorLayout = styled.div`
    
`;

export const Author = styled(Commonh6)`
    margin-bottom:5px;
    text-transform:uppercase;
    text-align:center;
`;

export const Designation = styled(Commonpara)`
    margin-bottom:0px;
    text-align:center;
`;

export const TestimonialText = styled(Commonpara)`
    text-align:center;
`;

export const NextPrev = styled.div`
    text-align:center;
`;

export const ImgButton = styled.button`
    border:none;
    background:none;
    outline:0;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

