import React,{Component} from "react";
import Headermenu from "../containers/RealestatePage3/HeaderMenuBS";
import Banner from "../containers/RealestatePage3/Banner";
import About from "../containers/RealestatePage3/About";
import PropertyDetails from "../containers/RealestatePage3/PropertyDetails";
import Features from "../containers/RealestatePage3/Features";
import Gallery from "../containers/RealestatePage3/Gallery";
import SaleDetails from "../containers/RealestatePage3/SaleDetails";
import Specifics from "../containers/RealestatePage3/Specifics";
import Testimonials from "../containers/RealestatePage3/Testimonials";
import Team from "../containers/RealestatePage3/Team";
import Footer from "../containers/RealestatePage3/Footer";
import GlobalStyle from "../containers/RealestatePage3/Common/global-styles";
import Fonts from "../containers/RealestatePage3/Common/fonts";
import "../components/layout.css";
import SEO from "../components/seo";


import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class RealestatePage3 extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpenLightBox: false,
            lightBoxImages:[],
            photoIndex: 0,
        }
        this.openLightBox = this.openLightBox.bind(this);
    }

    openLightBox(imageArray,idx)
    {
        const images = [];
        for(const [index,value] of imageArray.Slider.entries()){
            console.log(index);
            images.push(value.GallerySliderImg.childImageSharp.fluid.src);
        }
        this.setState(
            {
                isOpenLightBox: true,
                lightBoxImages:images,
                photoIndex:idx
            }
        );
    }

    render() {
        return(
                <div>
                {
                    <div>
                        <Fonts/>
                        <GlobalStyle />
                        <main>
                        <SEO 
                                title="Florida Horse Ranch For Sale | Northeast Florida Horse Ranch / Estate"
                                description="Enjoy this expansive estate with acreage, horse barns, a large home and other income properties"
                            />
                            <Headermenu />
                            <Banner />
                            <About />
                            <PropertyDetails />
                            <Features />
                            <Gallery openLightBox = {this.openLightBox} />
                            <SaleDetails />
                            <Specifics />
                            <Testimonials />
                            <Team />
                        </main>
                        <Footer />
                    </div>
                }
                {
                    this.state.isOpenLightBox && (
                    <Lightbox
                        mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
                        nextSrc={this.state.lightBoxImages[(this.state.photoIndex + 1) % this.state.lightBoxImages.length]}
                        prevSrc={this.state.lightBoxImages[(this.state.photoIndex + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length]}
                        onCloseRequest={() => this.setState({ isOpenLightBox: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.lightBoxImages.length,
                        })
                        }
                    />
                    )
                }   
                </div> 
                )
            }
        }
  
  export default RealestatePage3;
  