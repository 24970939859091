import React,{Component}from 'react';
import {TestimonialWrapper,Heading,HeadingSpan,TestimonialLayout,
    SliderOuterWrapper,SliderWrapper,TestimonialCard,
    ImageHolder,QuoteHolder,StyledIconOpen,TestimonialText,AuthorLayout,Author,
    Designation,NextPrev,ImgButton,PrevImg,NextImg
} from './testimonials.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        console.log("PROPS"+JSON.stringify(this.props.TestimonialsData.TestimonialCards));
        const settings = {
            arrows:false,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return (
            <TestimonialWrapper id="testimonialsSection">
                <Container>
                    <Heading>
                    {this.props.TestimonialsData.Heading} 
                    <HeadingSpan>{this.props.TestimonialsData.HeadingSpan}</HeadingSpan>
                    </Heading>
                    <TestimonialLayout>
                        <SliderOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.TestimonialsData.TestimonialCards.map((item,idx) => {
                                return <SliderWrapper>
                                        <TestimonialCard>
                                            <ImageHolder>
                                                <GatsImg 
                                                    fluid={item.Img.childImageSharp.fluid} 
                                                    alt=""
                                                    className="authorImg"
                                                />
                                            </ImageHolder>
                                            <QuoteHolder>
                                                <StyledIconOpen />
                                            </QuoteHolder>
                                            <TestimonialText>
                                                {item.TestimonialText} 
                                            </TestimonialText>
                                            <AuthorLayout>
                                                <Author>
                                                {item.Author} 
                                                </Author>
                                                <Designation>
                                                {item.Designation} 
                                                </Designation>
                                            </AuthorLayout>
                                        </TestimonialCard>
                                    </SliderWrapper>
                                })
                            }
                            </Slider>
                        </SliderOuterWrapper>
                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.TestimonialsData.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.TestimonialsData.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>
                    </TestimonialLayout>
                </Container>
            </TestimonialWrapper>
        );
    }
}
export default () => (
    <StaticQuery
        query={graphql`
            query {
                    realestatePage3Json{
                        Testimonials{
                        Heading
                        HeadingSpan
                        TestimonialCards{
                            Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            TestimonialText
                            Author
                            Designation
                        }
                        PrevImg
                        NextImg
                    }
                }
            }
            
        `}
        render={(data) => (
            <Testimonials TestimonialsData={data.realestatePage3Json.Testimonials}/>
        )}
    />
  )