import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SaleDetailsSection,
    HeadingLayout,
    Heading,
    HeadingSpan,
    SaleDetailsLayout,
    SaleDetailsImage,
    SaleDetailsText
} from './saleDetails.style';

import { useStaticQuery, graphql } from 'gatsby';

const SaleDetails = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
            realestatePage3Json{
                SaleDetails{
                    Heading
                    HeadingSpan
                    SaleDetailsData{
                        Img
                        SaleDetailsText
                    }
                }
            }
        }
    `);
    const SaleDetailsData = JSONData.realestatePage3Json.SaleDetails;

    return(
        <SaleDetailsSection id="saleDetailsSection">
            <Container>
                <Row>
                    <Col md={12}>
                        <HeadingLayout>
                            <Heading>
                            {SaleDetailsData.Heading}
                            <HeadingSpan>{SaleDetailsData.HeadingSpan}</HeadingSpan>
                            </Heading>
                        </HeadingLayout>
                    </Col>
                </Row>
                <Row>
                    {
                        SaleDetailsData.SaleDetailsData.map((salesDetailsObj, idx) => {
                        return <Col lg={3} md={6} sm={6} key={idx}>
                                <SaleDetailsLayout>
                                    <SaleDetailsImage src={salesDetailsObj.Img} alt="" />
                                    <SaleDetailsText>
                                    {salesDetailsObj.SaleDetailsText}
                                    </SaleDetailsText>
                                </SaleDetailsLayout>
                            </Col>
                        })
                    }
                </Row>
            </Container>
        </SaleDetailsSection>

    );
}

export default SaleDetails;