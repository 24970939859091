import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading,Commonpara,Commonh4} from '../Common/common.style';
import BackgroundImg from '../../../assets/realestate-3-images/features-section-bg.jpg';

export const FeaturesSection = styled.section`
    background-image:url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;


export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const MainHeading = styled(SectionHeading)`
    text-align:left;
`;

export const Border = styled.img`
    height:20px;
    width:100px;
`;

export const FeaturesSingle = styled.div`
    text-align:center;
    margin-bottom:30px;
`;

export const FeaturesImg = styled.img`
    width: auto;
    height: 70px;
    margin-bottom:20px;
`;

export const Heading = styled(Commonh4)`
    line-height:1;
    margin-bottom:15px;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const FeaturesDesc = styled(Commonpara)`
    text-align:center;
    margin-bottom:0px;
`;
